<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]" :default-checked-keys="[1]"
				 :highlight-current="true" :load="loadNode1" lazy @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<!-- <img :src="data.path" style="margin-right: 10px;" /> -->

						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.regionname}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form :inline="true">
						<el-form-item label="房间名/房屋地址：">
							<el-input class="shuru" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button type="primary" @click="dialogTableVisible = true" v-if="newbutton"><i class="el-icon-plus"></i> 新增</el-button>
						</el-form-item>
					</el-form>
					
					<div class="xuanze">
						<el-checkbox v-model="checked" @change="quanxuan">全选</el-checkbox>
						<el-button type="primary" plain @click="queren()">批量删除</el-button>
					</div>
				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe ref="table" style="width: 100%;margin-top: 20px;" @selection-change="selsChange">
						<el-table-column type="selection" width="80" align="center"></el-table-column>
						<el-table-column prop="housename" label="房间名/房屋地址" align="center">
						</el-table-column>
						<el-table-column label="操作" width="200" align="center">
							<template slot-scope="scope">
								<el-button @click="shanchu(scope.row.id)" type="primary">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper"
						 :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>

				<el-dialog custom-class="tanchuang" :title="type == 2?'新增房间信息':'新增房屋信息'" width='30%' :visible.sync="dialogTableVisible" :before-close="handleClose"
				 :close-on-click-modal="false">
					<div v-show="type == 2" class="loudong" style="display: inline-block;">
						<p class="biaoti" style="text-align: left;">{{shequname}} {{xiaoquname}}</p>
						<div style="text-align: left;">
							<el-radio v-model="radio" label="1">批量新增</el-radio>
							<el-radio v-model="radio" label="0">单条新增</el-radio>
							<el-radio v-model="radio" label="2">单元房间批量新增</el-radio>
						</div>
						<div v-show="radio == 1">
							<div style="text-align: left;">
								<div>
									<span>本楼栋共</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="danyuan1"
									 placeholder="请输入内容"></el-input>
									<span>单元</span>
								</div>
								<div>
									<span>每个单元</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="ceng1"
									 placeholder="请输入内容"></el-input>
									<span>层</span>
								</div>
								<div>
									<span>单元每层</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="fangjian1"
									 placeholder="请输入内容"></el-input>
									<span>房间</span>
								</div>
							</div>
						</div>
						<div v-show="radio == 0">
							<div style="text-align: left;">
								<div>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="danyuan2"
									 placeholder="请输入内容"></el-input>
									<span>单元</span>
								</div>
								<div>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="ceng2"
									 placeholder="请输入内容"></el-input>
									<span>层</span>
								</div>
								<div>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/[^\d]/g,'')" v-model="fangjian2"
									 placeholder="请输入内容"></el-input>
									<span>房间号</span>
								</div>
							</div>
						</div>
						<div v-show="radio == 2">
							<div style="text-align: left;">
								<div>
									<span>本楼栋第</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="danyuan3"
									 placeholder="请输入内容"></el-input>
									<span>单元</span>
								</div>
								<div>
									<span>本单元共</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="ceng3"
									 placeholder="请输入内容"></el-input>
									<span>层</span>
								</div>
								<div>
									<span>单元每层</span>
									<el-input style="width: 200px;" type='text' oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="fangjian3"
									 placeholder="请输入内容"></el-input>
									<span>房间</span>
								</div>
							</div>
						</div>
						<div style="text-align: left;" v-show="abc == 1">{{danyuan2}}单元{{fj}}</div>
						<div style="text-align: left;">
							<el-button type="primary" @click="onSubmit">立即创建</el-button>
							<el-button @click="close">取消</el-button>
						</div>
					</div>
					<el-form v-show="type == 1" ref="form2" label-width="auto">
						<el-form-item label="房屋地址">
							<el-input v-model="address"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">确定</el-button>
							<el-button @click="close">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getxiaoqulist,
		getbuildlist,
		deletehouselist,
		addmulbuildhouse,
		gethouselist
	} from '@/api/managexiaoqu'
	export default {
		data() {
			return {
				address:'',
				type:'',
				time: new Date(),
				treeId: "",
				searchName: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				radio: '1',
				danyuan1: '',
				ceng1: '',
				fangjian1: '',
				danyuan2: '',
				ceng2: '',
				fangjian2: '',
				danyuan3: '',
				ceng3: '',
				fangjian3: '',
				defaultProps: {
					children: 'regionlist',
					label: 'regionname',
					isLeaf: 'leaf'
				},
				treeSelectId: "",
				tree: [],
				newbutton: false,
				regioncode: '',
				buildid: '',
				levelcode: '',
				shequname: '',
				xiaoquname: '',
				abc: 0,
				fj: '',
				loading1: true,
				loading2: true,
				checked: false,
				duoxuanList: []
			}
		},
		watch: {
			radio(val, oldVal) {
				this.danyuan1 = '';
				this.ceng1 = '';
				this.fangjian1 = '';
				this.danyuan2 = '';
				this.ceng2 = '';
				this.fangjian2 = '';
				this.danyuan3 = '';
				this.ceng3 = '';
				this.fangjian3 = '';
			},
			ceng2(val, oldVal) {
				this.abc = 0;
				if (val.length != 0) {
					this.abc = 1;
				}
				var ceng;
				this.fj = '';
				if (val.length == 1 && this.fangjian2.length == 1) {
					ceng = '' + '0' + val;
					if (this.fangjian2 != '') {
						this.fj = ceng + '0' + this.fangjian2;
					}
				} else if (val.length > 1 && this.fangjian2.length == 1) {
					ceng = '' + val;
					if (this.fangjian2 != '') {
						this.fj = ceng + '0' + this.fangjian2;
					}
				} else if (val.length == 1 && this.fangjian2.length > 1) {
					ceng = '' + '0' + val;
					if (this.fangjian2 != '') {
						this.fj = ceng + this.fangjian2;
					}
				} else if (val.length > 1 && this.fangjian2.length > 1) {
					ceng = '' + val;
					if (this.fangjian2 != '') {
						this.fj = ceng + this.fangjian2;
					}
				}
			},
			fangjian2(val, oldVal) {
				this.abc = 0;
				this.fj = '';
				if (val != '') {
					if (this.ceng2.length == 1 && val.length == 1) {
						this.fj = '' + '0' + this.ceng2 + '0' + val;
						this.abc = 1;
					} else if (this.ceng2.length > 1 && val.length == 1) {
						this.fj = '' + this.ceng2 + '0' + val;
						this.abc = 1;
					} else if (this.ceng2.length == 1 && val.length > 1) {
						this.fj = '' + '0' + this.ceng2 + val;
						this.abc = 1;
					} else if (this.ceng2.length > 1 && val.length > 1) {
						this.fj = '' + this.ceng2 + val;
						this.abc = 1;
					}
				}
			}
		},
		mounted() {
			this.getTree();
			this.getList();
		},
		methods: {
			loadNode1(node, resolve) {
				var that = this;
				var list;
				if (node.level == 0) {
					return resolve(this.tree);
					
				} else if (node.level == 1) {
					return resolve(node.data.regionlist);
				}else if(node.level == 2){
					// return resolve(node.data.regionlist);
					that.levelcode = node.data.region_level;
					that.regioncode = node.data.regioncode;
					getbuildlist({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionlevel:that.levelcode,
						regioncode:that.regioncode,
						page:1,
						pagesize:1,
						seachname: '',
						type:2
					}).then((res) => {
						if (res.result == '200') {
							list = res.detail;
							for(let i = 0;i<list.length;i++){
								list[i].regionname = list[i].buildname + '(' + list[i].nowhousenum + ')';
								list[i].levelcode = list[i].region_level;
								list[i].type = node.data.type;
								list[i].leaf = true;
							}
							return resolve(list);
						} else {
							that.$message.error(res.description);
						}
					})
				}else{
					return resolve([]);
				}
			},
			selsChange(val) {
				this.duoxuanList = [];
				for (var i = 0; i < val.length; i++) {
					this.duoxuanList.push(val[i].id);
				}
				if (val.length == this.tableData.length) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			queren() {
				var that = this;
				if (that.duoxuanList.length == 0) {
					that.$message.error('请选择需要删除的房间');
					return;
				}
				var jishu = 0;
				var no = 0;
				that.$confirm('此操作将永久删除选中的房间, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					for (var i = 0; i < that.duoxuanList.length; i++) {
						deletehouselist({
							'x-access-token': that.GLOBAL.token(),
							userid: that.GLOBAL.adminId(),
							housecode: that.duoxuanList[i]
						}).then((res) => {
							if (res.result == '200') {
								jishu++;
								if(jishu == that.duoxuanList.length){
									that.$message({
										type: 'success',
										message: '全部删除成功!'
									});
									that.currentPage = 1;
									that.searchName = '';
									that.checked = false;
									that.duoxuanList = [];
									that.getTree();
									that.getList();
								}
							} else {
								no = that.duoxuanList.length - jishu;
								that.$message({
									message: '已删除' + jishu + '个房间，' + no + '个房间删除失败。',
									type: 'warning'
								});
								that.currentPage = 1;
								that.searchName = '';
								that.checked = false;
								that.duoxuanList = [];
								that.getTree();
								that.getList();
								return;
							}
						})
					}
					
				}).catch(() => {

				});

			},
			quanxuan() {
				let that = this;
				if (that.checked == true) {
					for (var i = 0; i < that.tableData.length; i++) {
						that.$refs.table.toggleRowSelection(that.tableData[i], true);
					}
				} else {
					for (var j = 0; j < that.tableData.length; j++) {
						that.$refs.table.toggleRowSelection(that.tableData[j], false);
					}
				}
			},
			getTree() {
				this.loading1 = true;
				getxiaoqulist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					type:2
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
					
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].regionname = this.tree[a].name + '(' + this.tree[a].housenum + ')';
							this.tree[a].regioncode = '';
							this.tree[a].ji = 1;
							for (var i = 0; i < this.tree[a].regionlist.length; i++) {
								this.tree[a].regionlist[i].name = this.tree[a].name;
								this.tree[a].regionlist[i].social = a;
								this.tree[a].regionlist[i].num = i;
								this.tree[a].regionlist[i].ji = 2;
								let leixing = '';
								if(this.tree[a].regionlist[i].type == 1){
									leixing = '(农村组)';
								}else if(this.tree[a].regionlist[i].type == 2){
									leixing = '(小区)';
								}
								this.tree[a].regionlist[i].regionname = this.tree[a].regionlist[i].regionname + leixing + '(' + this.tree[a].regionlist[i].nowhousenum +
									')';
								if (this.tree[a].regionlist[i].buildlist) {
									this.tree[a].regionlist[i].regionlist = this.tree[a].regionlist[i].buildlist;
									for (var j = 0; j < this.tree[a].regionlist[i].regionlist.length; j++) {
										this.tree[a].regionlist[i].regionlist[j].type = this.tree[a].regionlist[i].type;
										this.tree[a].regionlist[i].regionlist[j].shequ = this.tree[a].name;
										this.tree[a].regionlist[i].regionlist[j].xiaoqu = this.tree[a].regionlist[i].regionname;
										this.tree[a].regionlist[i].regionlist[j].ji = 3;
										this.tree[a].regionlist[i].regionlist[j].lastnum = this.tree[a].regionlist[i].num;
										this.tree[a].regionlist[i].regionlist[j].num = j;
										this.tree[a].regionlist[i].regionlist[j].social = a;
										this.tree[a].regionlist[i].regionlist[j].levelcode = this.tree[a].levelcode;
										this.tree[a].regionlist[i].regionlist[j].regionname = this.tree[a].regionlist[i].regionlist[j].build_id +
											'栋' + '(' + this.tree[a].regionlist[i].regionlist[j].nowhousenum + ')';
									}
								}
					
							}
						}
						// this.tableData = this.tree[0].regionlist;
						// this.$nextTick(() => {
						// 	this.$refs.tree.setCurrentKey(3);
						// })
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				var that = this;
				that.radio = '1';
				that.danyuan1 = '';
				that.ceng1 = '';
				that.fangjian1 = '';
				that.danyuan2 = '';
				that.ceng2 = '';
				that.fangjian2 = '';
				that.danyuan3 = '';
				that.ceng3 = '';
				that.fangjian3 = '';
				that.address = "";
				done();
			},

			onSubmit() {
				let that = this;
				if(that.type == 2){
					let danyuan;
					let ceng;
					let fangjian;
					let cengshu;
					let fjhao;
					if (that.radio == '1') {
						danyuan = that.danyuan1;
						ceng = that.ceng1;
						fangjian = that.fangjian1;
						if (!that.danyuan1) {
							that.$message.error('请填写添加单元数');
							return;
						}
						if (that.danyuan1[0] == 0) {
							that.$message.error('请填写正确的单元数');
							return;
						}
						if (!that.ceng1) {
							that.$message.error('请填写添加层数');
							return;
						}
						if (that.ceng1[0] == 0) {
							that.$message.error('请填写正确的层数');
							return;
						}
						if (!that.fangjian1) {
							that.$message.error('请填写添加房间数');
							return;
						}
						if (that.fangjian1[0] == 0) {
							that.$message.error('请填写正确的房间数');
							return;
						}
					} else if (that.radio == '0') {
						danyuan = that.danyuan2;
						ceng = that.ceng2;
						if (!that.danyuan2) {
							that.$message.error('请填写单元号');
							return;
						}
						if (that.danyuan2[0] == 0) {
							that.$message.error('请填写正确的单元号');
							return;
						}
						if (!that.ceng2) {
							that.$message.error('请填写层号');
							return;
						}
						if (that.ceng2[0] == 0) {
							that.$message.error('请填写正确的层号');
							return;
						}
						if (!that.fangjian2) {
							that.$message.error('请填写房间号');
							return;
						}
						if (that.fangjian2.length > 2) {
							that.$message.error('请填写正确的房间号');
							return;
						}
						if (that.ceng2.length == 1) {
							cengshu = '' + '0' + that.ceng2;
						} else {
							cengshu = that.ceng2;
						}
						if (that.fangjian2.length == 1) {
							fjhao = '' + '0' + that.fangjian2;
						} else {
							fjhao = that.fangjian2;
						}
					
						fangjian = '' + cengshu + fjhao;
					} else {
						danyuan = that.danyuan3;
						ceng = that.ceng3;
						fangjian = that.fangjian3;
						if (!that.danyuan3) {
							that.$message.error('请填写添加单元数');
							return;
						}
						if (that.danyuan3[0] == 0) {
							that.$message.error('请填写正确的单元数');
							return;
						}
						if (!that.ceng3) {
							that.$message.error('请填写添加层数');
							return;
						}
						if (that.ceng3[0] == 0) {
							that.$message.error('请填写正确的层数');
							return;
						}
						if (!that.fangjian3) {
							that.$message.error('请填写添加房间数');
							return;
						}
						if (that.fangjian3[0] == 0) {
							that.$message.error('请填写正确的房间数');
							return;
						}
					}
					addmulbuildhouse({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionCode: that.regioncode,
						buildid: that.buildid,
						cellid: danyuan,
						floor: ceng,
						room: fangjian,
						piliang: that.radio,
						regionlevel: that.levelcode
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								message: '添加成功',
								type: 'success'
							});
							that.danyuan1 = '';
							that.ceng1 = '';
							that.fangjian1 = '';
							that.danyuan2 = '';
							that.ceng2 = '';
							that.fangjian2 = '';
							that.searchName = '';
							that.radio = '1';
							that.address = '';
							that.getTree();
							that.getList();
							that.dialogTableVisible = false;
						} else {
							that.$message.error(res.description);
						}
					})
				}else if(that.type == 1){
					if (!that.address) {
						that.$message.error('请填写房屋地址');
						return;
					}
					addmulbuildhouse({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionCode: that.regioncode,
						houseName:that.address,
						buildid: that.buildid,
						regionlevel: that.levelcode
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								message: '添加成功',
								type: 'success'
							});
							that.danyuan1 = '';
							that.ceng1 = '';
							that.fangjian1 = '';
							that.danyuan2 = '';
							that.ceng2 = '';
							that.fangjian2 = '';
							that.searchName = '';
							that.radio = '1';
							that.address = '';
							that.getTree();
							that.getList();
							that.dialogTableVisible = false;
						} else {
							that.$message.error(res.description);
						}
					})
				}
				
			},
			close() {
				var that = this;
				that.danyuan1 = '';
				that.ceng1 = '';
				that.fangjian1 = '';
				that.danyuan2 = '';
				that.ceng2 = '';
				that.fangjian2 = '';
				that.radio = '1';
				that.address = '';
				that.dialogTableVisible = false;
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.checked = false;
				this.duoxuanList = [];
				this.getList();
			},
			handleCurrentChange(val) {
				this.checked = false;
				this.currentPage = val;
				this.duoxuanList = [];
				this.getList();
			},
			shanchu(a) {
				console.log(a)
				var that = this;
				
				that.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deletehouselist({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						housecode: a
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.currentPage = 1;
							that.searchName = '';
							that.checked = false;
							that.duoxuanList = [];
							that.getTree();
							that.getList();
						} else {
							that.$message.error(res.description);
						}
					})

				}).catch(() => {

				});

			},
			getList() {
				var that = this;
				that.loading2 = true;
				gethouselist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					regioncode: that.regioncode,
					buildid: that.buildid,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					seachname: that.searchName
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			nodeClick(data) {
				console.log(data);
				var that = this;
				// that.tableData = [];
				that.searchName = '';
				that.levelcode = '';
				that.regioncode = '';
				that.currentPage = 1;
				that.buildid = '';
				that.shequname = '';
				that.xiaoquname = '';
				that.checked = false;
				that.duoxuanList = [];
				if (data.ji == 1) {
					that.newbutton = false;
					that.levelcode = data.levelcode;
				} else if (data.ji == 2) {
					that.newbutton = false;
					that.levelcode = data.region_level;
					that.regioncode = data.regioncode;
				} else {
					that.newbutton = true;
					that.regioncode = data.regioncode;
					that.levelcode = data.levelcode;
					that.buildid = data.build_id;
					that.shequname = data.levelname;
					that.xiaoquname = data.regionname;
					that.type = data.type;
				}
				that.getList();
			},
			chaxun() {
				this.currentPage = 1;
				this.getList();
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.biaoti {
		font-size: 18px;
		padding: 0 !important;
	}

	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.loudong>p,
	.loudong>div,
	.loudong>div>div>div {
		padding: 10px 0;
	}

	.loudong .el-input {
		margin: 0 10px;
	}

	.xuanze {
		float: right;
	}
	.xuanze button{
		margin-left: 20px;
	}
</style>
